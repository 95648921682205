import React from 'react'
import Layout from '../components/layout'
import Paddy from "../components/Paddy";
import CardFlex from '../components/CardFlex';
import { longforms } from "../data/longforms";
import { hackathons } from "../data/hackathons";
import { blog } from "./../data/blog";

const IndexPage = () => {

  return (
    <Layout>
      <div>
        <Paddy name="bio">
          <h1>Tanay Vardhan</h1>
          <p>
            Hello, I'm a full-stack engineer at PayPal Credit, working on the <a href="https://www.paypal.com/us/business/buy-now-pay-later">Global Pay Later</a> team. Previously, I founded <a href="https://turkbox.io">Turkbox</a>, a platform to pay for news by classifying data used for training machine learning models. I'm passionate about building web applications to visualize data, automate tedious tasks for users, and solve problems across varied disciplines. While building applications, I like paying attention to user experience and adopting a product-centric approach.
          </p>
        </Paddy>
        <Paddy title={`Work`}>
          <CardFlex data={longforms} />
        </Paddy>
        <Paddy title={`From the blog`}>
          <CardFlex openLinksInNewTab={true} data={blog} />
        </Paddy>
        <Paddy title={`Hackathon Projects`}>
          <CardFlex openLinksInNewTab={true} data={hackathons} />
        </Paddy>
      </div>
    </Layout>
  )
}

export default IndexPage
