
/* <========================== Load Images ==============================> */
import newsGazetteThumbnail from "./../images/covers/blog/newsgazette.png";
import turkboxThumbnail from "./../images/covers/blog/turkbox.jpg";
import csIllinoisThumbnail from "./../images/covers/blog/cs@illinois.png";


const blog = [
    {
        "name": "News-Gazette Interview", 
        "description": "An interview with the News-Gazette about my work on Turkbox",
        "thumbnail": newsGazetteThumbnail,
        "link": "https://www.news-gazette.com/news/wired-in-tanay-vardhan/article_b645ef7f-b54e-51f5-a63c-dfd67b8a1beb.html"
    },
    {
        "name": "Updates from Turkbox", 
        "description": "Turkbox placed 4th at the Cozad New Venture Competition and received funding from the Google News Initiative",
        "thumbnail": turkboxThumbnail,
        "link": "https://medium.com/@tanayvardhan/building-turkbox-a-way-to-pay-for-news-by-training-ai-ed19ef1bb02e"
    },
    {
        "name": "CS Department Feature", 
        "description": "My interview with the News-Gazette was also featured on the Illinois CS department website",
        "thumbnail": csIllinoisThumbnail,
        "link": "https://cs.illinois.edu/news/wired-tanay-vardhan"
    }
];



export {
    blog
}