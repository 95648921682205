import React from "react";
import Card from "./Card";

const RowDisplay = (props) => (
    
    <div className="card-flex">
        {props.data.map((dataPoint, key) => (
            <Card 
                key={key}
                heading={dataPoint.name}
                synopsis={dataPoint.description}
                subtleText={dataPoint.subtleText}
                link={dataPoint.link}
                thumbnail={dataPoint.thumbnail}
                bannerText={dataPoint.bannerText}
                openLinksInNewTab={props.openLinksInNewTab}
            />
        ))}
    </div>

)


export default RowDisplay;