import React, { Component } from 'react';

class Card extends Component {

    render = () => {

        const content = (
            <div className="card">
                <span className="thumbnail" style={
                    {
                        backgroundImage: `url('${this.props.thumbnail}')`
                    }} role="img" aria-label={this.props.heading}></span>
                <div className="info-text">
                    <p className="subtle-text">{this.props.subtleText}</p>
                    <p className="heading">{this.props.heading}</p>
                    <p className="synopsis">{this.props.synopsis}</p>
                </div>
            </div>
        )

        return (
            <div className="card-enclosure">
                {
                    this.props.openLinksInNewTab ? <NewTabLink link={this.props.link}>{content}</NewTabLink> :
                        <a href={this.props.link}>{content}</a>
                }
            </div>
        )
    }

}

export default Card


const NewTabLink = (props) => (
    <a href={props.link} rel="noopener noreferrer" target="_blank">
        {props.children}
    </a>
)