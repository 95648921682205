/* <========================== Load Images ==============================> */
import emiwayThumbnail from "./../images/covers/hackathon/emiway.png";
import krannertThumbnail from "./../images/covers/hackathon/krannert.png";
import packerooThumbnail from "./../images/covers/hackathon/packeroo.png";
import embarkThumbnail from "./../images/covers/hackathon/embark.png";
import laprasThumbnail from "./../images/covers/hackathon/lapras.png";
import parkcoreThumbnail from "./../images/covers/hackathon/parkcore.png";


const hackathons = [
    {
        "name": "Emiway",
        "description": "VR app to visualize furniture recommendations given by a deep learning model",
        "link": "https://devpost.com/software/emiway-ipsref", 
        "thumbnail": emiwayThumbnail,
        "bannerText": "🎖Wayfair API Prize",
        "subtleText": "HackDartmouth V"
    },
    {
        "name": "Krannert",
        "description": "Progressive web app to book flights and hotels collaboratively",
        "link": "https://devpost.com/software/krannert", 
        "thumbnail": krannertThumbnail,
        "bannerText": "🎖Amadeus API Prize",
        "subtleText": "HackIllinois 2019"
    },
    {
        "name": "Packeroo",
        "description": "Skill for Amazon Alexa to parse itineraries and assist with packing for trips",
        "link": "https://www.hackathon.io/83884", 
        "thumbnail": packerooThumbnail,
        "bannerText": "🎖Grand Prize Winner",
        "subtleText": "AngelHacks Boston 2017"
    },
    {
        "name": "Lapras",
        "description": "A personalized banking experience using a bot and spending predictions",
        "link": "https://devpost.com/software/lapras", 
        "thumbnail": laprasThumbnail,
        "bannerText": "🎖Capital One API Prize",
        "subtleText": "HackPrinceton 2016"
    },
    {
        "name": "Embark",
        "description": "A mobile app to find flights for vacations requiring minimal input from the user",
        "link": "https://devpost.com/software/embark", 
        "thumbnail": embarkThumbnail,
        "bannerText": "🎖Amadeus API Prize",
        "subtleText": "HackIllinois 2016"
    },
    {
        "name": "ParkCore",
        "description": "An app to crowdsource the availability of parking spots for Detroit residents",
        "link": "https://devpost.com/software/parkcore", 
        "thumbnail": parkcoreThumbnail,
        "subtleText": "MHacks 8"
    },
]

export {
    hackathons
}